import type { SessionData } from '#auth'
import type { Address } from '~/types/api'

export default function useUser() {
  function getFirstName(user: SessionData) {
    return user.name.split(' ')[0]
  }

  const getAddresses = () => {
    return useAsyncData(
      'addresses',
      () => useNuxtApp().$api.user.getAddresses(),
      {
        dedupe: 'defer',
        getCachedData: (
          key,
          nuxt,
        ): {
          residential: Address | null
          billing: Address | null
          delivery: Address[]
        } => nuxt.payload.data[key] || nuxt.static.data[key],
      },
    )
  }

  const getDeliveryAddress = async (id: number) => {
    const addresses = await getAddresses()
    return addresses.data.value!.delivery.find((address) => address.id === id)!
  }

  return {
    getFirstName,
    getAddresses,
    getDeliveryAddress,
  }
}
